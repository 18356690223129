angular
    .module('madApp')
    .directive('revenueTable', revenueTableDirective);

function revenueTableDirective() {
    var directive = {
        restrict: 'A',
        templateUrl: 'app/vl/dashboards/directives/table/revenue.table.html',
        scope: {
            details: "=details",
            totalSummary: "=totalSummary",
            callfunc:"&callfunc",
            type:"=ftype",
            key:"=fkey",
            throb:"=throb",
            show:"=show",
            ulbtype: "=ulbtype",
            officertype: "=officertype"
        },
        link: linkFunc,
        controller: 'RevenueTableDirectiveController'
    };

    return directive;

    function linkFunc(scope, element, attrs) {
      
    }
}
