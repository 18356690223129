(function() {
    'use strict';

    angular
        .module('madApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$urlRouterProvider'];

    function stateConfig($stateProvider,$urlRouterProvider) {
        $stateProvider.state('vl.dashboards', {
            abstract: true,
            parent: 'vl',
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('vassar');
                    return $translate.refresh();
                }]
            }
        });
        $urlRouterProvider.when('/','/revenue');
    }
})();
