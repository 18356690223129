angular
    .module('madApp')
    .controller('vlFilterFormController', vlFilterFormController)
;

vlFilterFormController.$inject = ['$scope', '$state', '$stateParams', '$filter', '$translate', '$translatePartialLoader', 'ParseLinks', 'AlertService','RevenueServices'];
function vlFilterFormController($scope, $state, $stateParams, $filter, $translate, $translatePartialLoader, ParseLinks, AlertService,RevenueServices) {
    $translatePartialLoader.addPart('vassar');
    $translate.refresh();
    /**
     * Mont constant, fixed to April of this year
     *
     * @type {Date}
     */
     $scope.revenueOfficerOrInspectorFilter = false;
     if($state.current.name === "vl.dashboards.revenue.collections" || $state.current.name === "vl.dashboards.revenue.dcbprivate" ||
     $state.current.name === "vl.dashboards.revenue.dcbstate" || $state.current.name === "vl.dashboards.revenue.dcbcentral" ||
     $state.current.name === "vl.dashboards.revenue.dcbcourt" || $state.current.name === "vl.dashboards.revenue.dcbvacant" ||
     $state.current.name === "vl.dashboards.revenue.dcb"){
       $scope.revenueOfficerOrInspectorFilter = true;
     }
  var minMonth = new Date();
  var current_year = minMonth.getFullYear()-1;
  if(minMonth.getMonth()<=3){
    current_year = current_year -1;
  }
  minMonth.setMonth(3); //TODO: Constant - make it configurable
  minMonth.setDate(1);
  minMonth.setFullYear(current_year);
    /**
     * Query data to be populated from the UI.
     * @type {{fromDate: null, toDate: null, gradeType: string, grade: null, selectedULB: null}}
     */
    $scope.queryData = {
        fromDate: null,
        toDate: null,
        gradeType: 'district',
        grade: null,
        selectedULB: null
    };

    $scope.formHidden = true;
    /**
     * toggle form
     */
    $scope.toggleForm = function () {
        $scope.formHidden = !$scope.formHidden;
    };
    // Date picker format
    $scope.format = 'shortDate';
    $scope.fromDate = {
        opened: false
    };

    $scope.toDate = {
        opened: false
    };
    /**
     * From date picker configuration
     * @type {{minDate: Date, maxDate: Date, datepickerMode: string, maxMode: string, minMode: string}}
     */
    $scope.fromDateOptions = {
        minDate: minMonth,
        maxDate: new Date(),
        datepickerMode: 'day',
        maxMode: 'day',
        minMode: 'day'
    };
    /**
     * To date picker configuration
     * @type {{minDate: Date, maxDate: Date, datepickerMode: string, maxMode: string, minMode: string}}
     */
    $scope.toDateOptions = {
        minDate: $scope.queryData.fromDate || minMonth,
        maxDate: new Date(),
        datepickerMode: 'day',
        maxMode: 'day',
        minMode: 'day'
    };
    /**
     * Open from-date date-picker popup.
     */
    $scope.fromDatePickerOpen = function () {
        $scope.fromDate.opened = true;
    };
    /**
     * Open the to-date date-picker popup.
     */
    $scope.toDatePickerOpen = function () {
        $scope.toDate.opened = true;
    };


    $scope.$watch('queryData.fromDate', function (newValue, oldValue) {
        if (newValue) {
            $scope.toDateOptions.minDate = newValue;
            $scope.queryData.toDate = null;
        }
    });

  $scope.$watch('queryData.gradeType', function (newValue, oldValue) {
    if (newValue) {
      $scope.queryData.grade = null;
      $scope.queryData.selectedULB = null;
    }
  });
  $scope.$watch('queryData.grade', function (newValue, oldValue) {
    if (newValue) {
      $scope.queryData.selectedULB = null;
    }
  });

  $scope.$watch('ulbtype', function (newValue, oldValue) {
    if (newValue && newValue!=oldValue && $state.params.type!=undefined) {
      if($state.params.type==="ULB" || $state.params.type==="ULB_BILL" || $state.params.type==="ULB_REVENUE_OFFICER" || $state.params.type==="ULB_REVENUE_INSPECTOR"){
        var stateParams = {};
        stateParams["id"] = $state.params.id;
        stateParams["type"] = $scope.ulbtype;
        if ($state.params.fromDate) {
          stateParams["fromDate"] = $state.params.fromDate;
        }
        if ($state.params.toDate) {
          stateParams["toDate"] = $state.params.toDate;
        }
        $state.go($state.current.name, stateParams);
      }
    }
  });

  /**
  * Get all ULB for based on selected Grade and Grade-Type
  * @returns {*}
  */
  $scope.getULBForGrade = function () {
    var searchFilter = {};
    searchFilter[$scope.queryData.gradeType] = $scope.queryData.grade;
    return $filter('filter')($scope.ulbList, searchFilter);

  };
  /**
  * Get labels for null option, sets a new value based on the gradeType selected
  * @returns {*}
  */
  $scope.getDefaultGradeTypeLabel = function () {
    if ('district' === $scope.queryData.gradeType) return $filter('translate')('vassar.directives.revenue.detail.option.label.All Districts');
    if ('region' === $scope.queryData.gradeType) return $filter('translate')('vassar.directives.revenue.detail.option.label.All Regions');
    if ('grade' === $scope.queryData.gradeType) return $filter('translate')('vassar.directives.revenue.detail.option.label.All ULB Types');
  };

  /**
  * Process request
  */

  $scope.vlSubmit = function () {
    $scope.propertyType = null;
    if($scope.queryData.selectedULB){
      $state.go($state.current.name, {id: $scope.queryData.selectedULB,type:"ULB",
      fromDate:$scope.queryData.fromDate,
      toDate:$scope.queryData.toDate});
    }
    else {
      $state.go($state.current.name, {
        id: $scope.queryData.grade, type: $scope.queryData.gradeType.toUpperCase(),
        fromDate: $scope.queryData.fromDate,
        toDate: $scope.queryData.toDate},{
        });

      }
      $scope.formHidden = true;
    };

    $scope.send = function (type,key) {
      $scope.propertyType = null;
      if(type && key){
        $state.go($state.current.name, {id: key,type:type});
      }
      else {
        $state.go($state.current.name,{id: null,type:null});
      }
    };


    $scope.getULBGradeDesc = function (ulbGrade) {
      if(ulbGrade === "I") return "Grade I";
      else if(ulbGrade === "II") return "Grade II";
      else if(ulbGrade === "III") return "Grade III";
      else if(ulbGrade === "NP") return "Nagar Panchayat";
      else if(ulbGrade === "Special") return "Special Grade";
      else if(ulbGrade === "Corp") return "Corporation";
      else return ulbGrade;
    };

    $scope.$watch("propertyType",function(newValue,oldValue){
      if (newValue!=oldValue){
        RevenueServices.setPropertyType(newValue);
      }
    });

    $scope.checkTopLevel = function (){
      if($scope.queryData.gradeType === "all_ulb" || $scope.queryData.gradeType === "all_ward"){
        return false;
      }
      return true;
    }

    $scope.showAll = function (){
      if($state.current.name.indexOf('dcb')!= -1 || $state.current.name.indexOf('collections')!= -1
      || $state.current.name.indexOf('receipts')!= -1){
        return true;
      }
      return false;
    }
}
